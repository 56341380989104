import React, { useEffect, useState, useMemo, useRef, memo, useReducer, useCallback } from 'react';
import  PulseLoader  from 'react-spinners/PulseLoader';


type Props = {
    isLoading: boolean
}

const PulseLoaderComponent: React.FC<Props> = ({ isLoading }) => {
    return <>
        {isLoading && <PulseLoader color={'#7E8299'} loading={isLoading} size={5} />}
    </>
}

export default PulseLoaderComponent
