import { stringifyRequestQuery, toINR } from 'src/helpers'
import { TransformerTableMainState } from 'src/modules/TransformerReport/Context'
import React, { useEffect, useMemo, useState } from 'react'
import PulseLoaderComponent from 'src/components/commons/PulseLoaderComponent'
import { getTransformersSummary } from 'src/modules/TransformerReport/Context/core/_requests'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getFilterReducerState } from 'src/redux/Reducers/filterReducer'


type summury = {
    "APPROVED": number | 0,
    "REJECTED": number | 0,
    "IN_REVIEW": number | 0,
    // "FAILED": number | 0,
    "TOTAL": number | 0,
}

const defaultSummury: summury = {
    "APPROVED": 0,
    "REJECTED": 0,
    "IN_REVIEW": 0,
    // "FAILED": 0,
    "TOTAL": 0,
}



const SubSummuryComponent = ({ name, count, isLoading }: any) => {
    return <div className="flex flex-col">
        <div className="text-blue-500 text-xs mb-1">{name}</div>
        <div className="flex items-center text-sm">
            <span className="text-gray-400 me-2"># </span>
            {isLoading ? (
                // If loading, display loading spinner (PulseLoaderComponent)
                <PulseLoaderComponent isLoading={isLoading} />
            ) : (
                // If not loading, display the count or "-" if count is falsy
                <span className="">{count ? <span className="">{toINR(count)}</span> : "-"}</span>
            )}
        </div>
    </div>

}



const SummuryComponent = () => {

    const { state, updateState } = TransformerTableMainState();
    const { tableData, summaryData, isLoading, page, items_per_page } = useSelector((state: RootState) => getFilterReducerState(state))
    const [summury, setsummury] = useState<summury>(defaultSummury)
    const [loading, setloading] = useState<boolean>(false)
    // console.log(summury, summaryData, "summary");

    let tableDatas: any = useMemo(() => summaryData || [], [summaryData])
    console.log(tableDatas);
    


    useEffect(() => {
        setsummury(defaultSummury)
        // fetchConsumerSummury()
        return () => { }
    }, [state])


    const fetchConsumerSummury = async () => {
        setloading(true)
        let newState = { ...state };
        let newF = { ...newState.f };
        if (newF?.status) {
            delete newF["status"]
        }
        newState = { ...newState, f: newF }
        const query = stringifyRequestQuery(newState);
        const apiResp: any = await getTransformersSummary(query)
        // console.log({ apiResp })

        if (apiResp?.summary) {
            let items: any = {};
            Object.entries(apiResp?.summary).forEach(([key, value]) => {
                items = { ...items, [key]: value }
            })
            // console.log({ items });
            setsummury(items)
            setloading(false)
        }
    }


    return (

        <div className="flex">
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-2 flex-grow">
                <SubSummuryComponent isLoading={loading} name="Total Readings" count={tableDatas?.TOTAL} />
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-2 flex-grow">
                <SubSummuryComponent isLoading={loading} name="Approved Readings" count={tableDatas?.APPROVED} />
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-2 flex-grow">
                <SubSummuryComponent isLoading={loading} name="Rejected Readings" count={tableDatas?.REJECTED} />
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-2 flex-grow">
                <SubSummuryComponent isLoading={loading} name="In Review Readings" count={tableDatas?.IN_REVIEW} />
            </div>
        </div>

    )
}

export default SummuryComponent