import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { QueryState, stringifyObjectToRequestQuery, stringifyRequestQuery } from 'src/helpers';
import axios, { AxiosError } from 'axios';
import { getAllTransformers } from 'src/modules/TransformerReport/Context/core/_requests';
import { viewDetails, viewExistingDetails } from 'src/modules/TransformerDetails/Context/core/_requests';
import { useLocation } from 'react-router-dom';


interface ItransformerDetailsReducerState extends QueryState {
    isLoading: boolean,
    error: boolean,
    message: string | undefined,
    tableData: any[],
    existingData: any[],
    req_no: string | undefined,
    feeder_id: string,
    request_status: string | undefined
    district_id: string,
    startDate: string,
    endDate: string,
    filterType: string,
    page: number
    items_per_page: 10 | 30 | 50 | 100,
    status: string | undefined,
    code: string
}


export const initaiState: ItransformerDetailsReducerState = {
    isLoading: false,
    error: false,
    message: undefined,
    request_status: undefined,
    tableData: [],
    existingData: [],
    req_no: undefined,
    feeder_id: "",
    district_id: "",
    startDate: "",
    endDate: "",
    filterType: "",
    filter: {},
    page: 1,
    items_per_page: 30,
    status: "IN_REVIEW",
    code: ""

}


const transformToQueryString = (data: any) => {

    // console.log({ data });



    const filterkeys: string[] = ["startDate", "endDate", "feeder_id", "district_id", "status"];
    const f_keys: string[] = ["req_no", "code", "name"];

    let filters: any = {};
    let f: any = {};

    data && Object.entries(data).map(([key, value]) => {
        if (filterkeys.length > 0 && filterkeys.includes(key)) {
            filters[key] = value
        }
        else if (f_keys.length > 0 && f_keys.includes(key)) {
            f[key] = value
        }
    })

    return stringifyRequestQuery({
        filter: filters,
        f: f,
        items_per_page: data.items_per_page,
        page: data.page
    })

}

export const fetchTransformersDetailsByReqNoAsync: any = createAsyncThunk(
    'TransformersDetailsByReqNo/fetchAll',
    async (_, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().transformerDetailsReducerState
            // console.log(getFilterState);

            let newFilterState = { ...getFilterState }
            delete newFilterState["req_no"];

            const queryString = transformToQueryString(newFilterState);
            // console.log({ queryString })
            const response: any = await viewDetails(getFilterState?.req_no, queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = "";//response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const fetchTransformersExistingDetailsCodeAsync: any = createAsyncThunk(
    'TransformersExistingDetailsByCode/fetchAll',
    async (_, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().transformerDetailsReducerState
            console.log(getFilterState);

            let newFilterState = { ...getFilterState }
            delete newFilterState["req_no"];

            const queryString = transformToQueryString(newFilterState);
            // console.log({ queryString })
            const response: any = await viewExistingDetails(getFilterState?.code);

            console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = "";//response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const transformerDetailsReducer = createSlice({
    name: 'transformerDetailsReducer',
    initialState: initaiState,
    reducers: {
        setReqNo: (state, action: PayloadAction<any>) => {
            state.req_no = action.payload;
        },
        setCode: (state, action: PayloadAction<any>) => {
            state.code = action.payload;
        },
        setStartDate: (state, action: PayloadAction<any>) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action: PayloadAction<any>) => {
            state.endDate = action.payload;
        },
        setType: (state, action: PayloadAction<any>) => {
            state.filterType = action.payload;
        },
        setPage: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
        setItemsPerPage: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload;
        },
        setPaggination: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload.pageSize;
            state.page = action.payload.pageIndex;
        },
        setFilters: (state, action: PayloadAction<{
            endDate: string,
            startDate: string,
            district_id: string,
            feeder_id: string
        }>) => {
            state.endDate = action.payload.endDate;
            state.startDate = action.payload.startDate;
            state.district_id = action.payload.district_id;
            state.feeder_id = action.payload.feeder_id;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload?.data?.message;
            state.request_status = action.payload?.status;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        reseteState: (state) => {
            state = initaiState
        },
        setStatus: (state, action: PayloadAction<any>) => {
            state.status = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransformersDetailsByReqNoAsync.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.tableData = payload.data;
                state.error = false;
                state.message = undefined;
                state.items_per_page = payload?.payload?.pagination?.items_per_page;
                state.page = payload?.payload?.pagination?.page;
                state.total_records = payload?.payload?.pagination?.total_records;
                state.total = payload?.payload?.pagination?.total;
            })
            .addCase(fetchTransformersDetailsByReqNoAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.tableData = []
                state.isLoading = true
            })
            .addCase(fetchTransformersDetailsByReqNoAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })

            .addCase(fetchTransformersExistingDetailsCodeAsync.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.isLoading = false;
                state.existingData = payload;
                state.error = false;
                state.message = undefined;
                state.items_per_page = payload?.payload?.pagination?.items_per_page;
                state.page = payload?.payload?.pagination?.page;
                state.total_records = payload?.payload?.pagination?.total_records;
                state.total = payload?.payload?.pagination?.total;
            })
            .addCase(fetchTransformersExistingDetailsCodeAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.existingData = []
                state.isLoading = true
            })
            .addCase(fetchTransformersExistingDetailsCodeAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })
    }


});

export const { setStartDate, setEndDate, setType, setError, resetError, reseteState, setItemsPerPage, setPage, setPaggination, setFilters, setReqNo, setStatus, setCode } = transformerDetailsReducer.actions;

export const getTransformerDetailsReducerState = (state: RootState) => state.transformerDetailsReducerState;

export default transformerDetailsReducer.reducer;