import { UsersListLoading } from 'src/components/ui/UsersListLoading';
import React, { useEffect, useState, useMemo, useRef, memo, useReducer, useCallback } from 'react';
import { stringifyObjectToRequestQuery } from 'src/helpers';
import DateInput from 'src/components/commons/DateInput';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getFilterReducerState } from 'src/redux/Reducers/filterReducer';
import { SelectDropdown } from 'src/components/commons/DropdownSelect';


type Props = {
    data?: any,
    start_date?: any,
    end_date?: any,
}

const GISDashboardMain = ({ data, start_date, end_date }: any) => {

    const [loader, setLoader] = useState(false)
    // const { district_id, _date, tcn_substation_id } = useSelector((state: RootState) => getFilterReducerState(state))
    const [filterType, setfilterType] = useState<any>("")
    // console.log(data)
    const userData = useMemo(() => data, [data])
    const [filterData, setfilterData] = useState<any>({
        req_no: "",
        // start_date: "",
        // end_date: ""
    })

    // console.log(userData)

    const handleSelect = (selectedValue: string) => {
        setfilterType(selectedValue)
    };

    const [date, setDate] = React.useState<Date | undefined>(new Date())

    const iframeRef = useRef<any>(null)

    const BASE_URL = process.env.REACT_APP_URL_GIS;

    // const [url, seturl] = useState<string>(BASE_URL + "/?dist_id=2&feed_id=195");
    const [url, seturl] = useState<string | undefined>(BASE_URL);

    const handleSubmit = () => {
   
    }

    useEffect(() => {
        const newFilter = {
            req_no: userData?.req_no || null,
            // start_date: start_date|| null,
            // end_date: end_date|| null
        }
        let apiQuery = { ...newFilter }
        const query = stringifyObjectToRequestQuery(apiQuery)
        // console.log({ query });

        seturl(BASE_URL + "?" + query)
        // console.log(url);
        
    
      return () => { }
    }, [])
    



    return <>
        <div className="space-y-2">
            <div className='w-full'>
                <iframe
                    style={{ height: "30vh", width: "100%" }}
                    onLoad={() => setLoader(false)}
                    ref={iframeRef}
                    src={url}
                    className={' w-max-content'}
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full">
                </iframe>
            </div>
        </div>
    </>
}

export default GISDashboardMain
