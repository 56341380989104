import ReportSummaryTable from 'src/modules/TransformerReport/Context/TableCom/ReportSummaryTable';
import { columns } from 'src/modules/TransformerReport/Context/TableCom/columns';
import React, { useMemo } from 'react'
import { useQueryResponse, useQueryResponsePagination } from 'src/modules/TransformerReport/Context';
import { useQueryResponseLoading } from 'src/modules/TransformerReport/Context';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getFilterReducerState } from 'src/redux/Reducers/filterReducer';
import { UsersListLoading } from 'src/components/ui/UsersListLoading';
import { AlertCircle } from 'lucide-react';

type Props = {}

const TransformerTable = (props: Props) => {

    const { tableData, isLoading, page, items_per_page } = useSelector((state: RootState) => getFilterReducerState(state))
    // console.log({ tableData });

    let tableDatas: any = useMemo(() => tableData || [], [tableData])

    tableDatas = useMemo(() => tableDatas && tableDatas?.map((o: any, i: any) => {
        return {
            ...o,
            sr_no: (i + 1) + (items_per_page * (page == 1 ? 0 : page - 1))
        }
    }), [tableDatas, page, items_per_page])

    // console.log(tableDatas);



    return (
        <>

            <ReportSummaryTable columns={columns} data={tableDatas || []} />

        </>
    )
}

export default TransformerTable