import { CheckCircle2, XCircle } from 'lucide-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'src/components/ui/dialog'
import { Input } from 'src/components/ui/input'
import { Label } from 'src/components/ui/label'
import { Textarea } from 'src/components/ui/textarea'
import { useAuth } from 'src/modules/Auth/Core'
import { setRemarks, setReqNo, setStatus, updateTransformersStatusByReqNoAsync } from 'src/redux/Reducers/updateStatusReducer'

type Props = {
    data?: any
}

const RejectModal = (data: Props) => {

    const [isOpen, setisOpen] = useState("hide")
    const [remark, setremark] = useState("")
    const dispatch = useDispatch()

    const { currentUser } = useAuth();
    // console.log(data);


    const handleReject = () => {
        dispatch(setStatus("REJECTED"))
        dispatch(setReqNo(data))
        dispatch(setRemarks(remark))
        dispatch(updateTransformersStatusByReqNoAsync({ currentUser }))
        setisOpen("hide")
    }


    return (
        <>

            <Button size={'lg'} className="bg-red-300 text-black hover:text-white hover:bg-red-400" onClick={() => {
                setisOpen("show")
                // setSelectedUserData(value); onApprove(value);
            }}>

                Reject
            </Button>
            <Dialog open={isOpen == "show"} onOpenChange={(e) => setisOpen(e ? "show" : "hide")} >
                <DialogContent className="lg:max-w-[825px]">
                    <DialogHeader>
                        <DialogTitle>Update Status</DialogTitle>
                    </DialogHeader>

                    <div className=''>
                        {/* {rowData && rowData?.map(([key, values]: any, index: any) => (
                            <React.Fragment key={index}>
                                <div className='col-span-1 text-sm'>{key && convertToCamelCase(key)} :</div>
                                <div className='col-span-1 text-sm font-bold'>{
                                    key == "createdAt" ? getFormatToDateTimeWithoutSecondsTogether(values) : values ? String(values) : "-"
                                }</div>
                            </React.Fragment>
                        ))} */}

                        <form>
                            <div className="w-full items-center">
                                <div className="flex flex-col space-y-1.5">
                                    <Label htmlFor="name">Remarks</Label>
                                    <Textarea id="name" placeholder="Enter Remark" value={remark}
                                        onChange={(e) => setremark(e.target.value)} />
                                </div>
                            </div>
                        </form>
                    </div>
                    <DialogFooter>
                        <Button className='bg-red-300 hover:bg-red-500' onClick={handleReject}>Reject</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RejectModal