import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns'
import { Button } from '../../../components/ui/button'
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { DatePickerWithRange } from 'src/components/commons/DatePickerWithRange'
import { useDispatch, useSelector } from 'react-redux'
import DistrictFilter from 'src/modules/Filters/Districts'
import { RootState } from 'src/redux/store'
import FeederFilter from 'src/modules/Filters/Feeders'
import { fetchAllApprovedTransformersListAsync, getApprovedFilterReducerState, setDistrict, setFeeder, setFilters } from 'src/redux/Reducers/approvedFilterReducer'



type Props = {}


const TransformerFilters = (props: Props) => {

    const { district_id, feeder_id, startDate, endDate } = useSelector((state: RootState) => getApprovedFilterReducerState(state))
    const dispatch = useDispatch()


    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>();

    useEffect(() => {
        setSelectedDate({
            from: startOfMonth(new Date()),
            to: endOfMonth(new Date()),
        })
        return () => { }
    }, [])

    const handleDateSelect = (selectedRange: any) => {
        setSelectedDate(selectedRange);
    };


    const formattedStartDate = selectedDate?.from ? format(selectedDate?.from, "yyyy-MM-dd") : '';
    const formattedEndDate = selectedDate?.to ? format(selectedDate?.to, "yyyy-MM-dd") : '';

    const handleApplyFiltes = () => {
        dispatch(setFilters({
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            district_id: district_id,
            feeder_id: feeder_id
        }))

        dispatch(fetchAllApprovedTransformersListAsync())
    }


    return (
        <>
            <div className='flex space-x-2'>
                <DistrictFilter value={district_id !== undefined ? district_id : ''} onChange={(e: any) => {
                    dispatch(setDistrict(e));
                }} />
                <FeederFilter value={feeder_id !== undefined ? feeder_id : ''} district_id={district_id !== undefined ? district_id : ""} onChange={(e: any) => {
                    dispatch(setFeeder(e));
                }} />
                <DatePickerWithRange selectedDate={selectedDate} onDateSelect={handleDateSelect} />
                <Button onClick={handleApplyFiltes} >Submit</Button>
            </div>
        </>
    )
}

export default TransformerFilters