import { useEffect, useMemo, useState } from 'react';
import { QueryRequestProvider, QueryResponseProvider, TransformerTable } from './Context';
import SummuryComponent from '../TransformerReport/Components/SummuryComponent';
import { Card } from 'src/components/ui/card';
import TransformerFilters from 'src/modules/TransformerReport/Components/TransformerFilters';
import SubFilters from 'src/modules/TransformerReport/Components/SubFilters';



type Props = {}

const Main = (props: Props) => {



    return <>
        {/* <div className="flex justify-between items-center ">
            <div className='justify-self-start text-center font-bold'>All Feeders</div>
            <div className='flex justify-end space-x-2 items-center'>
              <TransformerFilters />
            </div>
        </div>
        <div className='p-2'>
            <FeederCards />
        </div> */}
        <div className='p-2 space-y-2'>
            <div className="flex justify-between items-center ">
                <div className='justify-self-start text-center font-bold'></div>
                <div className='flex justify-end space-x-2 items-center'>
                    <TransformerFilters />
                </div>
            </div>
            <Card className='space-y-2 p-2'>
                <SubFilters />
                <SummuryComponent />
                <TransformerTable />
            </Card>
        </div>
    </>
}

const TransformerMain: React.FC<Props> = ({ }) => {
    return <>
        {/* <QueryRequestProvider>
            <QueryResponseProvider> */}
                <Main />
            {/* </QueryResponseProvider>
        </QueryRequestProvider> */}
    </>

}

export default TransformerMain