import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { QueryState, stringifyObjectToRequestQuery, stringifyRequestQuery } from 'src/helpers';
import axios, { AxiosError } from 'axios';
import { getAllTransformers } from 'src/modules/TransformerReport/Context/core/_requests';
import { replaceData, updateStatus, viewDetails } from 'src/modules/TransformerDetails/Context/core/_requests';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchTransformersDetailsByReqNoAsync } from './transformerDetailsReducer';
import { useDispatch } from 'react-redux';
import ViewMoreDetailsModal from 'src/modules/TransformerDetails/Components/ViewMoreDetailsModal';
import ExistingDataModal from 'src/modules/TransformerDetails/Components/ExistingDataModal';
import { DialogTrigger } from 'src/components/ui/dialog';
import { log } from 'console';
import { useAuth } from 'src/modules/Auth/Core';


interface IupdateStatusReducerState extends QueryState {
    isLoading: boolean,
    error: boolean,
    message: string | undefined,
    tableData: any[],
    status: undefined | number,
    setStatus: string,
    req_no: string,
    remarks: string,
    page: number
    items_per_page: 10 | 30 | 50 | 100,
    showModalMessage: string,
    showModal: boolean
}


export const initaiState: IupdateStatusReducerState = {
    isLoading: false,
    error: false,
    message: undefined,
    tableData: [],
    status: undefined,
    setStatus: "",
    req_no: "",
    remarks: "",
    page: 1,
    items_per_page: 30,
    showModalMessage: "",
    showModal: false
}


const transformToQueryString = (data: any) => {

    const filterkeys: string[] = ["startDate", "endDate"];
    const f_keys: string[] = [];

    let filters: any = {};
    let f: any = {};

    data && Object.entries(data).map(([key, value]) => {
        if (filterkeys.length > 0 && filterkeys.includes(key)) {
            filters[key] = value
        }
        else if (f_keys.length > 0 && f_keys.includes(key)) {
            f[key] = value
        }
    })

    return stringifyRequestQuery({
        filter: filters,
        f: f,
        items_per_page: data.items_per_page,
        page: data.page
    })

}


export const updateTransformersStatusByReqNoAsync: any = createAsyncThunk(
    'TransformersStatusByReqNo/fetchAll',
    async (data, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().updateStatusReducerState
            const { currentUser }: any = data;
            // console.log(new Date(), new Date(Date.now()).toISOString())
            // console.log(currentUser)

            // let newFilterState = { ...getFilterState }
            // delete newFilterState["req_no"];
            let requestNo = getFilterState?.req_no?.data ? getFilterState?.req_no?.data : getFilterState?.req_no
            // console.log(requestNo, getFilterState?.req_no?.data);

            let query = {
                "status": getFilterState?.setStatus,
                "approver_id": currentUser?.id || "",
                "approver_name": currentUser?.name || "",
                "approver_phone": currentUser?.phone || "",
                "approved_at": new Date(Date.now()).toISOString(),
                "approver_remarks": getFilterState?.remarks || "",
            }

            const response: any = await updateStatus(requestNo, query);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                toast.success(response.message);
                thunkApi.dispatch(fetchTransformersDetailsByReqNoAsync())
                return response?.data;

                // Resolve the Promise with the successful response
            }
            else if (response.code == 208) {
                // const errorMessage = response.message;//response.data?.message || 'An error occurred during login';
                // // <ExistingDataModal message={errorMessage} />
                // thunkApi.dispatch(setMessage(errorMessage))
                // thunkApi.dispatch(setModalVisibility("show"))
                // // toast.error(errorMessage);
                // return thunkApi.rejectWithValue(errorMessage);
                // thunkApi.dispatch(setError({
                //     status: response?.code,
                //     message: response.message
                // }));
                // return thunkApi.rejectWithValue({
                //     status: response?.code,
                //     message: response.message
                // });
                thunkApi.dispatch(toggleModale({
                    status: response?.code,
                    message: response.message
                }));
                return response;
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            let error: any = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                let response: any = error?.response;
                let message = response?.message;
                thunkApi.dispatch(setError({
                    status: error.response?.data?.code,
                    message: message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data?.code,
                    message: message
                });
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue({
                status: undefined,
                message: error?.message
            });
        }
    }
);

export const replaceTransformersStatusByReqNoAsync: any = createAsyncThunk(
    'ReplaceTransformersStatusByReqNo/fetchAll',
    async (data, thunkApi: any) => {
        try {

            const { currentUser }: any = data;
            const getFilterState = thunkApi.getState().updateStatusReducerState

            let postData = {
                "req_no": getFilterState && getFilterState?.req_no,
                "status": getFilterState?.setStatus,
                "approver_id": currentUser?.id || "",
                "approver_name": currentUser?.name || "",
                "approver_phone": currentUser?.phone || "",
                "approved_at": new Date(Date.now()).toISOString(),
                "approver_remarks": getFilterState?.remarks || "",
            }

            const response: any = await replaceData(postData);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                // toast(response.message, { type: toast.TYPE.SUCCESS });
                // thunkApi.dispatch(fetchTransformersDetailsByReqNoAsync())
                toast.success(response.message);
                return response?.data;

                // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = response.message;//response.data?.message || 'An error occurred during login';
                // <ExistingDataModal message={errorMessage} />
                // thunkApi.dispatch(setMessage(errorMessage))
                // thunkApi.dispatch(setModalVisibility("show"))
                toast.error(errorMessage);
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);




export const updateStatusReducer = createSlice({
    name: 'updateStatusReducer',
    initialState: initaiState,
    reducers: {
        setReqNo: (state, action: PayloadAction<any>) => {
            state.req_no = action.payload;
        },
        // setFeeder: (state, action: PayloadAction<any>) => {
        //     state.feeder_id = action.payload;
        // },
        setRemarks: (state, action: PayloadAction<any>) => {
            state.remarks = action.payload;
        },
        setStatus: (state, action: PayloadAction<any>) => {
            state.setStatus = action.payload;
        },
        setPage: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
        setItemsPerPage: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload;
        },
        setPaggination: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload.pageSize;
            state.page = action.payload.pageIndex;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload?.data?.message;
            state.status = action.payload?.status;
        },
        toggleModale: (state, action: PayloadAction<any>) => {
            state.showModal = action.payload?.status;
            state.showModalMessage = action.payload?.message;
        },
        resetToggleModale: (state) => {
            state.showModal = false;
            state.showModalMessage = "";
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
            state.status = undefined
        },
        reseteState: (state) => {
            state = initaiState
        },
        setMessage: (state, action: PayloadAction<any>) => {
            state.showModalMessage = action.payload;
        },
        setModalVisibility: (state, action: PayloadAction<any>) => {
            state.showModal = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateTransformersStatusByReqNoAsync.fulfilled, (state, { payload }) => {
                if (payload.code == 200) {
                    state.isLoading = false;
                    state.tableData = payload.data;
                    state.error = false;
                    state.message = undefined;
                    state.items_per_page = payload?.payload?.pagination?.items_per_page;
                    state.page = payload?.payload?.pagination?.page;
                    state.total_records = payload?.payload?.pagination?.total_records;
                    state.total = payload?.payload?.pagination?.total;
                }
                else if (payload.code == 208) {
                    state.showModal = true;
                    state.showModalMessage = payload?.message
                }
            })
            .addCase(updateTransformersStatusByReqNoAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.tableData = []
                state.isLoading = true
            })
            .addCase(updateTransformersStatusByReqNoAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload?.message;
                state.status = payload?.status || undefined;
                state.isLoading = false;
            })

            .addCase(replaceTransformersStatusByReqNoAsync.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.tableData = payload.data;
                state.error = false;
                state.message = undefined;
                state.items_per_page = payload?.payload?.pagination?.items_per_page;
                state.page = payload?.payload?.pagination?.page;
                state.total_records = payload?.payload?.pagination?.total_records;
                state.total = payload?.payload?.pagination?.total;
            })
            .addCase(replaceTransformersStatusByReqNoAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.tableData = []
                state.isLoading = true
            })
            .addCase(replaceTransformersStatusByReqNoAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })
    }
});

export const { setReqNo, setRemarks, setStatus, setError, resetError, reseteState, setItemsPerPage, setPage, setPaggination, setMessage, setModalVisibility, toggleModale, resetToggleModale } = updateStatusReducer.actions;

export const getUpdateStatusReducerState = (state: RootState) => state.updateStatusReducerState;

export default updateStatusReducer.reducer;