import TransformerTable from 'src/modules/TransformerReport/Components/TransformerTable'
import React, { lazy } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import HeaderComponent from 'src/components/HeaderComponent'
import TransformerMain from 'src/modules/TransformerReport/TransformerMain'
import TransformerDetailsMain from 'src/modules/TransformerDetails/TransformerDetailsMain'
import LoginPage from 'src/modules/Auth/Components/LoginPage'
import TransformerDetailsCardExistingData from 'src/modules/TransformerDetails/Components/TransformerDetailsCardExistingData'
import ApprovedTransformerMain from 'src/modules/TransformerApproved/ApprovedTransformerMain'
import TransformerDetailsNewMain from 'src/modules/TransformerDetailsNew/TransformerDetailsNewMain'
import TransformerDetailsCardExisitingDataNew from 'src/modules/TransformerDetailsNew/Components/TransformerDetailsCardExisitingDataNew'



// const FederRoutePages = () => {
//   return <Routes>
//     <Route element={<><Outlet /></>}>
//       <Route path="analysis" element={<FeederCardsMain />} />
//       <Route path="analysis/live-pim" element={<LivePimMain />} />
//       <Route path="analysis/peak-monitoring" element={<PeakMonitoringMain />} />
//       <Route path="analysis/log-sheet" element={<LogDetailsTable />} />
//       <Route path="analysis/projection" element={<FeederProjectionMain />} />
//       {/* <Route index element={<Navigate to={'dashboard/feeder/analysis'} />} /> */}
//       <Route path={"*"} element={<>error</>} />
//     </Route>
//   </Routes>
// }


const AppRoutes = () => {

  return (
    <Routes>
      {/* <Route path='auth/*' element={<Navigate to="dashboard" />} /> */}
      <Route element={<HeaderComponent />}>
        <Route path='dashboard' element={<TransformerMain />} />
        {/* <Route path='dashboard/details' element={<TransformerDetailsMain />} /> */}
        <Route path='dashboard/details-new' element={<TransformerDetailsNewMain />} />
        {/* <Route path='dashboard/existing-details' element={<TransformerDetailsCardExistingData />} /> */}
        <Route path='dashboard/new-existing-details' element={<TransformerDetailsCardExisitingDataNew />} />
        <Route path='approved-details' element={<ApprovedTransformerMain />} />
        {/* <Route path="/more-charts/cards/*" element={<MoreChartsRoutePage />} />
        <Route path="/dashboard/*" element={<DashboardsRoutePage />} />
        <Route path="/ss-cards" element={<SubstationCardsMain />} /> */}
        <Route path={"*"} element={<>error</>} />
      </Route>
    </Routes>
  )
}

export default AppRoutes

