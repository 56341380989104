import React from 'react'
import { useMainContext } from './MainContext'
import { X } from 'lucide-react'

type Props = {

}

const RenderSelectedValues = ({ }: Props) => {

    const { selectedValues, toggleValue } = useMainContext()

    return (
        <div className='flex space-x-2 items-center flex-wrap flex-1 whitespace-nowrap'>
            {selectedValues && selectedValues.icon ? <span>{selectedValues.icon}</span> : null}
            {selectedValues && selectedValues.value ? <span>{selectedValues.label}</span> : null}
        </div>
    )
}

export default RenderSelectedValues