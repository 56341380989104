import { Separator } from 'src/components//ui/separator'
import { Phone } from 'lucide-react'
import React from 'react'

type Props = {
    rowData?: any
}

const MiscDetails = ({ rowData }: any) => {
    // console.log(rowData);

    return (
        <div>
            <div className="flex flex-col justify-start whitespace-nowrap">
                <span className='text-dark font-bold text-sm' data-bs-toggle='tooltip' title='District Name' >
                    {(rowData?.district_code) || '-'}
                </span>
                <span className='font-semibold text-xs' data-bs-toggle='tooltip' title='Feeder Name'>
                    {/* <span className='inline-flex items-center rounded-md bg-gray-50 px-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'><Phone size={12} /> </span>  */}
                    <span className='text-dark'>{(rowData?.feeder_name || "-")}</span>
                </span>
                <span className='font-semibold text-xs' >
                    <span className='flex space-x-2'
                    // className='inline-flex items-center rounded-md bg-gray-50 px-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 space-x-4'
                    >
                        <span data-bs-toggle='tooltip' title='Segment'>{(rowData?.segment || "-")}</span>
                        <Separator orientation="vertical"  className="text-dark"/>
                        <span data-bs-toggle='tooltip' title='Type'>{(rowData?.type || "-")}</span>
                        <Separator orientation="vertical" />
                        <span data-bs-toggle='tooltip' title='Capacity'>{(rowData?.capacity || "-")}</span>
                    </span>
                    {/* <span className='text-dark'>{(rowData?.feeder_code || "-")}</span> */}
                </span>
            </div>
        </div>
    )
}

export default MiscDetails