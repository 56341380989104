import axios, { AxiosError, AxiosResponse } from 'axios'
import { URL_APPROVED_TRANSFORMER_EXPORT, URL_APPROVED_TRANSFORMER_LIST } from 'src/apis'


export const getAllApprovedTransformers = (query: string) => {
  return axios
    .get(`${URL_APPROVED_TRANSFORMER_LIST}?${query}`)
    .then((d: AxiosResponse<any>) => d?.data)
}

export const getAllApprovedTransformersExport = (query: string) => {
  return axios
    .get(`${URL_APPROVED_TRANSFORMER_EXPORT}?${query}`)
    .then((d: AxiosResponse<any>) => d?.data)
}







