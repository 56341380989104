import React from 'react'
import { stringifyObjectToRequestQuery, stringifyRequestQuery } from 'src/helpers';
import { FileSpreadsheet } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { URL_APPROVED_TRANSFORMER_EXPORT } from 'src/apis';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getApprovedFilterReducerState } from 'src/redux/Reducers/approvedFilterReducer';

type Props = {}

const ExcelExport = (props: Props) => {

    const [isLoadingExportAPi, setisLoadingExportAPi] = React.useState<boolean>(false)
    const filterState = useSelector((state: RootState) => getApprovedFilterReducerState(state))

    const handleOnClickExport = () => {
        setisLoadingExportAPi(true)
        const newstate = filterState && JSON.parse(JSON.stringify(filterState));
        delete newstate["items_per_page"];
        delete newstate["page"];
        delete newstate["code"];
        delete newstate["error"];
        delete newstate["isLoading"];
        delete newstate["message"];
        delete newstate["name"];
        delete newstate["req_no"];
        delete newstate["request_status"];
        delete newstate["status"];
        delete newstate["summaryData"];
        delete newstate["tableData"];
        delete newstate["total"];
        delete newstate["filterType"];
        delete newstate["total_records"];
        console.log(newstate)
        const queryString = stringifyObjectToRequestQuery({ ...newstate, items_per_page: filterState?.total_records });
        console.log({ queryString });

        const baseUrl = URL_APPROVED_TRANSFORMER_EXPORT + "?" + queryString;
        console.log(baseUrl);
        var win: any = window.open(baseUrl, '_blank');
        win.focus();
        setisLoadingExportAPi(false)
    }

    return (
        <>
            <Button className={`mx-2 p-2 ${isLoadingExportAPi ? 'bg-secondary text-white' : 'bg-primary text-primary'} btn btn-sm btn-outline btn-outline-dashed h-30px h-md-40px flex items-center`}>
                {isLoadingExportAPi ? 'Exporting..' : <FileSpreadsheet onClick={handleOnClickExport} size={24} className={isLoadingExportAPi ? 'text-white' : 'text-white'} />}
            </Button>

        </>
    )
}

export default ExcelExport