import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from '../App';

import AppRoutes from './AppRoutes';
import { useAuth } from 'src/modules/Auth/Core';
import AuthRoutes from './AuthRoutes';

const { PUBLIC_URL } = process.env;

const Navigations = () => {

    const { currentUser } = useAuth();

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes >
                <Route element={<App />}>
                    {/* <Route path="/*" element={<AppRoutes />} />
                    <Route index element={<Navigate to="/dashboard" />} /> */}

                    {currentUser ? (
                        <>
                            <Route path="/*" element={<AppRoutes />} />
                            <Route index element={<Navigate to="/dashboard" />} />
                        </>
                    ) : (
                        <>
                            <Route path="auth/*" element={<AuthRoutes />} />
                            <Route path='*' element={<Navigate to="/auth" />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Navigations;
