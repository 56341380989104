import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Search } from 'lucide-react';

type Props = {
    options: any,
    onChange: (e: any) => void
}

const SearchInputByOptions = ({ options, onChange }: Props) => {


    const [key, setkey] = useState('');
    const [value, setvalue] = useState('');

    const onSubmit = () => {
        if (key == '' && value == '') {
            onChange && onChange({})
        }
        else if (key && !value) {
            toast.error("Please select type or enter value")
        }
        else onChange && onChange({ key, value })
    }

    useEffect(() => {
        if (key == '') {
            setvalue('')
            onSubmit();
        }
        return () => { }
    }, [key, value])



    return (
        <div className="mx-2 flex justify-end p-1 ">
            <div className="flex items-center bg-gray-100 space-x-2 p-1">
                <select
                    name="timezone"
                    aria-label="Select a Timezone"
                    data-control="select2"
                    data-placeholder="date_period"
                    className="form-select form-select-sm  p-1 bg-gray-100 "
                    onChange={(e) => setkey(e.target.value)}
                    value={key}
                >
                    <option value=""><span>All</span></option>
                    {options &&
                        Object.entries(options).map(([key, value]: [any, any]) => {
                            return <option value={key}><span>{value}</span></option>;
                        })}
                </select>
                <div className="mr-2">
                    <input
                        type="search"
                        name="assignedto"
                        className="form-control p-1  bg-gray-100 rounded-0"
                        placeholder="Enter to search"
                        onKeyDown={(e) => (e.key === 'Enter' ? onSubmit() : null)}
                        value={value}
                        onChange={(e) => {
                            setvalue(e.target.value);
                            if (!e.target.value) onChange({ key, value: undefined });
                        }}
                        disabled={key === ''}
                    />
                </div>
                <span className=" flex items-center w-8 h-8  bg-gray-100 ">
                    <Search
                        style={{ pointerEvents: key === '' && value === '' ? 'none' : 'auto' }}
                        className={`${key === '' && value === '' ? 'text-gray-300' : 'text-blue-400'} `}
                        size={20}
                        onClick={onSubmit}
                    />
                </span>
            </div>
        </div>


    )
}

export default SearchInputByOptions