import { Button } from 'src/components/ui/button';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { getFilterReducerState } from 'src/redux/Reducers/filterReducer';
import { RootState } from 'src/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransformersDetailsByReqNoAsync, setEndDate, setReqNo, setStartDate } from 'src/redux/Reducers/transformerDetailsReducer';

type Props = {
    rowData?: any
}

const ActionComp = ({ rowData }: any) => {
    // console.log(rowData);
    const navigate = useNavigate()
    const { startDate, endDate } = useSelector((state: RootState) => getFilterReducerState(state))
    const dispatch = useDispatch()


    const handleViewDetails = () => {
        // window.open(`/dashboard/details?code=${rowData?.code}&from_date=${startDate}&to_date=${endDate}`,'_blank')
        window.open(`/dashboard/details-new?code=${rowData?.code}&from_date=${startDate}&to_date=${endDate}`,'_blank')
        // navigate(`/dashboard/details?code=${rowData?.code}&from_date=${startDate}&to_date=${endDate}`)
        // dispatch(setStartDate(startDate))
        // dispatch(setEndDate(endDate))
        // dispatch(setReqNo(rowData?.req_no))
        // dispatch(fetchTransformersDetailsByReqNoAsync())
    }

    return (
        <>
            <div>
                <Button className='hover:text-white text-blue-500 bg-white hover:bg-blue-500'
                    onClick={handleViewDetails}
                >View</Button>
            </div>
        </>
    )
}

export default ActionComp