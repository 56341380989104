import React, { useState } from 'react'
import Viewer from 'react-viewer'
import { Card, CardContent } from 'src/components/ui/card'
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from 'src/components/ui/carousel'
import { toAbsoluteUrl } from 'src/helpers'

type Props = {
    data?: any
}

const ImagesCarousel = ({ data }: Props) => {

    const [visible, setVisible] = useState(false);

    const [images, setImages] = useState<any>([{
        src: "",
        alt: ""
    }]);

    const viewImage = () => {
        // fetchImagesFromEventID(rowData.event_id)
        setVisible(!visible)
    }

    const setImagesToView = (imageData: any) => {
        setVisible(true);
        if (imageData && imageData) {
            setImages(imageData.map((data: any) => {
                return {
                    src: data,
                    alt: ""
                }
            }));
        }
    }
    // console.log(data);

    const photosArray = Object.values(data?.photos);
    const imagesArray = data?.images;

    const mergedImagesArray: any = [...photosArray, ...imagesArray];

    console.log(mergedImagesArray);


    return (
        <>
            {visible && < Viewer
                visible={visible}
                onClose={viewImage}
                images={images}
            />}
            <div className='grid justify-center'>
                <Carousel
                    opts={{
                        align: "start",
                    }}
                    className="w-full max-w-4xl"
                >
                    <CarouselContent>

                        {mergedImagesArray && mergedImagesArray?.map((value: any, index: number) => {
                            // console.log(value?.imgName);

                            return (
                                <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/4">
                                    <div className="p-1">
                                        <div className='w-[850px]'>
                                            <div className="w-[150px] border h-[150px] "
                                                onClick={() => setImagesToView([value && value?.imgUrl])}
                                            >
                                                {value && value?.imgUrl ?
                                                    <img className="w-100 h-100 rounded-1 space-x-2 " alt='Image Loading Error'
                                                        src={value && value?.imgUrl}
                                                    /> : <img
                                                        src={toAbsoluteUrl('/media/error-image-photo-icon.svg')}
                                                        className='w-100 h-100 rounded-1  space-x-2 cursor-pointer'
                                                        title='No images found'
                                                    />   
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </CarouselItem>
                            )
                        }
                        )}
                    </CarouselContent>
                    <CarouselPrevious />
                    <CarouselNext />
                </Carousel>
            </div>
        </>

    )
}

export default ImagesCarousel