import axios, { AxiosError, AxiosResponse } from 'axios'
import { URL_APPROVED_TRANSFORMER_DETAILS_BY_ID, URL_REPLACE_TRANSFORMER_DETAILS, URL_TRANSFORMER_DETAILS_BY_ID, URL_TRANSFORMER_STATUS_UPDATE_BY_ID } from 'src/apis'


export const viewDetails = (id: string, query: string): Promise<any> => {
  return axios
    .get(`${URL_TRANSFORMER_DETAILS_BY_ID(id)}?${query}`)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response)
}

export const viewExistingDetails = (id: string): Promise<any> => {
  return axios
    .get(`${URL_APPROVED_TRANSFORMER_DETAILS_BY_ID(id)}`)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response)
}

export const updateStatus = (id: any, postData: any): Promise<any> => {
  return axios
    .put(`${URL_TRANSFORMER_STATUS_UPDATE_BY_ID(id)}`, postData)
    .then(async (d: any) => d.data)
}

export const replaceData = (postData: any): Promise<any> => {
  return axios
    .post(`${URL_REPLACE_TRANSFORMER_DETAILS}`, postData)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response)
}






