import { configureStore } from '@reduxjs/toolkit'
import filterReducer from './Reducers/filterReducer'
import transformerDetailsReducer from './Reducers/transformerDetailsReducer'
import updateStatusReducer from './Reducers/updateStatusReducer'
import approvedFilterReducer from './Reducers/approvedFilterReducer'

export const store = configureStore({
    reducer: {
        filterState: filterReducer,
        transformerDetailsReducerState: transformerDetailsReducer,
        updateStatusReducerState: updateStatusReducer,
        approvedFilterState:approvedFilterReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch