import React, { useEffect } from "react";
import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "src/helpers";
import { Button } from "../../components/ui/button";
import { Calendar } from "../../components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";

type Props = {
  className?: string;
  selectedDate?: DateRange | undefined;
  onDateSelect: (selectedDate: DateRange | undefined) => void;
};

export function DatePickerWithRange({
  className,
  selectedDate,
  onDateSelect,
}: Props) {

  const [date, setDate] = React.useState<DateRange | undefined>(selectedDate || {
    from: undefined,
    to: undefined,
  });

  useEffect(() => {
    setDate(selectedDate)
    return () => { }
  }, [selectedDate])


  const handleDateSelect = (selectedRange: DateRange | undefined) => {
    setDate(selectedRange);
    onDateSelect(selectedRange);
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal bg-white text-dark",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from || new Date()} // Set default month to 'from' date or current month if 'from' is not set
            selected={date}
            onSelect={handleDateSelect}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

