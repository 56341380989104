import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from './modules';
import { cn } from 'src/helpers';

const SidebarLayout = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const menuOptions = Menu;

  return (
    <div className="flex fixed z-50 ">
      <div
        className={`w-60 bg-[#40A2E3] h-screen p-3 pt-8 relative duration-300 `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          width: isHovered ? '15rem' : '4rem',
        }
        }
      >
        <ul className={`pt-3 ${isHovered ? 'space-y-3' : ''}`}>
          {menuOptions.map((Menu, index) => (
            <div key={index}>
              <h1 className={`text-zinc-950 font-semibold text-xl ${isHovered ? '' : 'hidden'}`}>{Menu.heading}</h1>
              {Menu.options?.map((menuItem, itemIndex) => (
                <li
                  key={itemIndex}
                  className={`rounded-md p-2 cursor-pointer hover:bg-light-white text-black text-xs items-center ${window.location.pathname.startsWith(menuItem?.path) ? 'text-white font-semibold' : ''}`}
                >
                  <Link to={menuItem.path} className='flex items-center space-x-4'>
                    <span>{menuItem.iconName(cn('text-slate-600 text-lg', { "text-white font-bolder": window.location.pathname.startsWith(menuItem?.path) }))}</span>
                    <span className={`duration-300 transition-all ease origin-left text-base  ${isHovered ? '' : 'hidden'}`}>
                      {menuItem.name}
                    </span>
                  </Link>
                </li>
              ))}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarLayout;
