import React, { useEffect } from 'react'
import { Card } from 'src/components/ui/card'
import OptionsComponentWithIcons from 'src/components/commons/OptionsComponentWithIcons'
import { OptionType } from 'src/helpers'
import { BadgeAlert, BadgeCheck, XCircle } from 'lucide-react'
import SelectPicker from 'src/components/ui/SelectPicker'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { fetchTransformersDetailsByReqNoAsync, getTransformerDetailsReducerState, setEndDate, setReqNo, setStartDate, setStatus } from 'src/redux/Reducers/transformerDetailsReducer'
import { useLocation } from 'react-router-dom'
import TransformerDetailsNew from './Components/TransformerDetailsNew'


type Props = {}

export const TRANSFORMER_STATUS_OPTIONS_ICONS: OptionType[] = [
    {
        label: 'ALL',
        value: "all",
        key: "TRANSFORMER_REPORT_STATUS_OPTIONS-ALL",
        // icon: <RiCheckboxCircleFill size={15} className='text-success' /> as any,
        // extraData: <div>(10)</div>
    },
    {
        label: 'Approved',
        value: "APPROVED",
        key: "TRANSFORMER_REPORT_STATUS_OPTIONS-APPROVED",
        icon: <BadgeCheck size={20} className='text-green-600' /> as any,
        // extraData: <div>(10)</div>
    },
    {
        label: 'Rejected',
        value: "REJECTED",
        key: "TRANSFORMER_REPORT_STATUS_OPTIONS-REJECTED",
        icon: <XCircle size={20} className='text-red-400' /> as any
    },
    {
        label: 'In Review',
        value: "IN_REVIEW",
        key: "TRANSFORMER_REPORT_STATUS_OPTIONS-IN_REVIEW",
        icon: <BadgeAlert size={20} className='text-amber-600' /> as any
    },
]

const TransformerDetailsNewMain = (props: Props) => {

    const { status } = useSelector((state: RootState) => getTransformerDetailsReducerState(state))

    const dispatch = useDispatch()
    const { search } = useLocation();

    useEffect(() => {
        return () => { dispatch(setStatus("IN_REVIEW")) }
    }, [])


    useEffect(() => {
        const params = search && search?.split('?')[1].split("&");
        // console.log({ params });  
        let searchParams: any = {};

        params && params.forEach((o: any) => {
            let splitParam = o?.split("=");
            searchParams[splitParam[0]] = splitParam[1];
        });

        dispatch(setStartDate(searchParams?.from_date))
        dispatch(setEndDate(searchParams?.to_date))
        dispatch(setReqNo(searchParams?.code))
        dispatch(fetchTransformersDetailsByReqNoAsync())

        console.log(searchParams);

        return () => { }
    }, [search])



    return (
        <>
            <div className="p-2">
                <Card className='p-2 space-y-2'>
                    <div className="flex justify-end">
                        <SelectPicker options={TRANSFORMER_STATUS_OPTIONS_ICONS} onchange={(e: any) => {
                            dispatch(setStatus(e != 'all' ? e : undefined));
                            dispatch(fetchTransformersDetailsByReqNoAsync())
                        }} value={status} searchable={false} placeholder='Select Status' />
                    </div>
                    <TransformerDetailsNew />
                </Card>
            </div>
        </>
    )
}

export default TransformerDetailsNewMain