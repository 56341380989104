import axios, { AxiosResponse } from 'axios'
import { URL_DISTRICT_FILTER} from 'src/apis'

export const fetchDistricts = (query: string): Promise<any> => {
  return axios
    .get(`${URL_DISTRICT_FILTER}`)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response)
}








