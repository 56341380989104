import axios, { AxiosResponse } from 'axios'
import { URL_FEEDERS_FILTER } from 'src/apis'

export const fetchFeeders = (query: string): Promise<any | undefined> => {
  return axios
    .get(`${URL_FEEDERS_FILTER}?${query}`)
    .then(async (d: any) => d?.data)
    .catch((e: any) => e?.response)
}








