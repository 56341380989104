import {useEffect} from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import LoginPage from 'src/modules/Auth/Components/LoginPage'
import { useAuth } from 'src/modules/Auth/Core';

const AuthRoutes = () => {

    const { currentUser } = useAuth();
    const navigate= useNavigate()
  
    useEffect(() => {
      if(currentUser){
        navigate('/')
      }
      return () => {};
    }, [currentUser]);

    return (
        <Routes>
            <Route path="login" element={<LoginPage/>} />
            {/* <Route path="register/*" element={<></>} /> */}
            <Route index element={<LoginPage/>} />
        </Routes>
    )
}
 
export default AuthRoutes

