import { Phone } from 'lucide-react'
import React from 'react'

type Props = {
    rowData?: any
}

const MeterDetails = ({ rowData }: any) => {
    // console.log(rowData);

    return (
        <div>
            <div className="flex flex-col justify-start">
                <span className='text-dark font-bold text-sm' data-bs-toggle='tooltip' title='Meter No' >
                    {(rowData?.meter_no) || '-'}
                </span>
                <span className='font-semibold text-xs' data-bs-toggle='tooltip' title='Meter Status'>
                    {/* <span className='inline-flex items-center rounded-md bg-gray-50 px-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'><Phone size={12} /> </span>  */}
                    <span className='text-dark'>{(rowData?.meter_status || "-")}</span>
                </span>
            </div>
        </div>
    )
}

export default MeterDetails