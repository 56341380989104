import { Phone } from 'lucide-react'
import React from 'react'

type Props = {
    rowData?: any
}

const MarketerDetails = ({ rowData }: any) => {
    // console.log(rowData);

    return (
        <div>
            <div className="flex flex-col justify-start whitespace-nowrap">
                <span className='text-dark font-bold text-sm' data-bs-toggle='tooltip' title='Marketer Name' >
                    {(rowData?.reviewer_name) || '-'}
                </span>
                <span className='font-semibold text-xs' data-bs-toggle='tooltip' title='Marketer Code'>
                    <span className='inline-flex  rounded-md bg-gray-50 px-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'><Phone size={12} /> </span> <span className='text-dark'>{(rowData?.reviewer_phone || "-")}</span>
                </span>
            </div>
        </div>
    )
}

export default MarketerDetails