import { AlertCircle, BadgeAlert, BadgeCheck, Phone, XCircle } from 'lucide-react'
import React, { useEffect, useMemo } from 'react'
import ProfileImageCOmponent from 'src/components/commons/ProfileImageCOmponent'
import { Card, CardContent, CardHeader, CardTitle } from 'src/components/ui/card'
import { getFormatDateKLocale, getFormatToDateTimeWithoutSecondsTogether, toAbsoluteUrl } from 'src/helpers'
import ImagesCarousel from './ImagesCarousel'
import QRCode from 'react-qr-code'
import GISDashboardMain from './GISMain'
import { UsersListLoading } from 'src/components/ui/UsersListLoading'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { fetchTransformersExistingDetailsCodeAsync, getTransformerDetailsReducerState, setCode } from 'src/redux/Reducers/transformerDetailsReducer'
import { useLocation } from 'react-router-dom'
import ExistingDataModal from './ExistingDataModal'

type Props = {}

const TransformerDetailsCardExisitingDataNew = (props: Props) => {

    const { isLoading, endDate, startDate, existingData } = useSelector((state: RootState) => getTransformerDetailsReducerState(state))
    console.log(existingData);

    const cardData = useMemo(() => existingData || [], [existingData])

    console.log(cardData)
    const dispatch = useDispatch()
    const { search } = useLocation();


    useEffect(() => {
        const params = search && search?.split('?')[1].split("&");
        // console.log({ params });  
        let searchParams: any = {};

        params && params.forEach((o: any) => {
            let splitParam = o?.split("=");
            searchParams[splitParam[0]] = splitParam[1];
        });


        dispatch(setCode(searchParams?.code))
        dispatch(fetchTransformersExistingDetailsCodeAsync())

        // console.log(searchParams);

        return () => { }
    }, [search])




    return (
        <>
            <Card className='space-y-2 p-2'>
                {cardData && cardData.length > 0 ? cardData?.map((value: any, index: number) => {
                    // console.log(value)
                    return (<>
                        <Card key={"Transformer Card" + index + 1} className='p-0 rounded-sm'>
                            <CardHeader className='p-0'>
                                <CardTitle>
                                    <div className="card-header p-3 flex justify-between bg-teal-100 " style={{ minHeight: "45px" }}>
                                        <div>
                                            <span className="font-bold text-xs text-gray-500 me-2">Request No:</span>
                                            <span className='font-bold text-sm me-2'>{value?.req_no ? value?.req_no : "-"}</span>

                                            {value?.status === "APPROVED" && (

                                                <span className="inline-flex bg-transparent border-2 border-green-400 text-green-500 rounded-full px-2 py-1 text-xs font-semibold mr-2">
                                                    <BadgeCheck className='text-green-500 cursor-pointer me-2' size={14} />
                                                    <span className='font-bold'>Approved</span>
                                                </span>
                                            )}

                                            {value?.status === "IN_REVIEW" && (
                                                <span className="inline-flex bg-transparent border-2 border-yellow-500 text-amber-600 rounded-full px-2 py-1 text-xs font-semibold mr-2">
                                                    <BadgeAlert className='text-amber-600  cursor-pointer me-2' size={18} />
                                                    <span className='font-bold'>In Review</span>
                                                </span>
                                            )}

                                            {value?.status === "REJECTED" && (
                                                <span className="inline-flex bg-transparent border-2 border-red-400 text-red-500 rounded-full px-2 py-1 text-xs font-semibold mr-2">
                                                    <span className='text-red-400  cursor-pointer'><XCircle className='me-2' size={20} /></span>
                                                    <span className='font-bold'>Rejected</span>
                                                </span>
                                            )}

                                            {
                                                value && value?.is_resubmit ? (
                                                    <span className="inline-flex bg-transparent border-2 border-black rounded-full px-2 py-1 text-xs font-semibold mr-2">
                                                        {/* <BadgeAlert className='text-amber-600  cursor-pointer me-2' size={18} /> */}
                                                        <span className='font-bold'>Resubmitted</span>
                                                    </span>
                                                ) : <></>
                                            }
                                        </div>
                                        <div className='font-bold text-sm'>{value?.createdAt ? getFormatDateKLocale(value?.createdAt) : "-"}</div>

                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardContent className="p-3 space-y-2">
                                <div className='grid grid-cols-4'>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Transformer</div>
                                        <div className='font-bold text-sm'>{value?.name ? value?.name : "-"}</div>
                                        <div className='font-bold text-sm'>{value?.code ? value?.code : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Feeder</div>
                                        <div className='font-bold text-sm'>{value?.feeder_name ? value?.feeder_name : "-"}</div>
                                        <div className='font-bold text-sm'>{value?.feeder_code ? value?.feeder_code : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Injection Substation</div>
                                        <div className='font-bold text-sm'>{value?.inj_substation ? value?.inj_substation : "-"}</div>
                                        {/* <div className='font-bold text-sm'>{value?.code ? value?.code : "-"}</div> */}
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">District</div>
                                        <div className='font-bold text-sm'>{value?.district_name ? value?.district_name : "-"}</div>
                                        {/* <div className='font-bold text-sm'>{value?.code ? value?.code : "-"}</div> */}
                                    </div>
                                </div>

                                <div>
                                    <div className="font-bold text-xs text-gray-400">Address</div>
                                    <div className='font-bold text-sm'>{value?.address ? value?.address : "-"}</div>
                                </div>


                                <div className='grid grid-cols-4'>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Capacity (kVA)</div>
                                        <div className='font-bold text-sm'>{value?.capacity ? value?.capacity : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Segment</div>
                                        <div className='font-bold text-sm'>{value?.segment ? value?.segment : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Type</div>
                                        <div className='font-bold text-sm'>{value?.type ? value?.type : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Position</div>
                                        <div className='font-bold text-sm'>{value?.transformer_position ? value?.transformer_position : "-"}</div>
                                    </div>
                                </div>

                                <div className='grid grid-cols-4'>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Make</div>
                                        <div className='font-bold text-sm'>{value?.make ? value?.make : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Serial Number</div>
                                        <div className='font-bold text-sm'>{value?.serial_no ? value?.serial_no : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Created At</div>
                                        <div className='font-bold text-sm'>{value?.createdAt ? getFormatToDateTimeWithoutSecondsTogether(value?.createdAt) : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Updated At</div>
                                        <div className='font-bold text-sm'>{value?.updatedAt ? getFormatToDateTimeWithoutSecondsTogether(value?.updatedAt) : "-"}</div>
                                    </div>
                                </div>

                                <div className='grid grid-cols-4'>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Metered Status</div>
                                        <div className='font-bold text-sm'>{value?.meter_status ? value?.meter_status : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Meter Remarks</div>
                                        <div className='font-bold text-sm'>{value?.meter_remarks ? value?.meter_remarks : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Meter Type</div>
                                        <div className='font-bold text-sm'>{value?.meter_type ? value?.meter_type : "-"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Meter No</div>
                                        <div className='font-bold text-sm'>{value?.meter_no ? value?.meter_no : "-"}</div>
                                    </div>
                                </div>

                                <div>
                                    <div className="font-bold text-xs text-gray-400">Survey Remarks</div>
                                    <div className='font-bold text-sm'>{value?.reviewer_remarks ? value?.reviewer_remarks : "-"}</div>
                                </div>

                                <div className='grid grid-cols-3'>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Marketer</div>
                                        <div className="flex items-center">
                                            {
                                                value?.employee_profile_image_url ?
                                                    <div className="me-5">
                                                        <span className="inline-block w-[50px] h-[70px]">
                                                            <ProfileImageCOmponent urlProps={value?.employee_profile_image_url} />
                                                        </span>
                                                    </div>
                                                    :
                                                    <div className="me-5">
                                                        <span className="inline-block w-[50px] h-[75px] rounded">
                                                            <img
                                                                src={toAbsoluteUrl('/media/blank.svg')}
                                                                className="w-full h-full"
                                                                alt=""
                                                            />
                                                        </span>
                                                    </div>
                                            }

                                            <div className="flex flex-col justify-start" >
                                                <span className="text-dark font-bold mb-1 text-sm">
                                                    {(value?.marketer_name) || '-'}
                                                </span>
                                                <span className="font-semibold mb-1 text-xs space-x-2" >
                                                    <span className='text-gray-400'>#</span>
                                                    <span className="text-dark">{(value?.marketer_id || "-")}</span>
                                                </span>
                                                <span className="font-semibold text-xs space-x-2" >
                                                    <span className='text-gray-400'>
                                                        <Phone className="inline-block align-middle" size={12} />
                                                    </span>
                                                    <span className="text-dark">{(value?.marketer_phone || "-")}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Surveyor</div>
                                        <div className="flex items-center">
                                            {
                                                value?.employee_profile_image_url ?
                                                    <div className="me-5">
                                                        <span className="inline-block w-[50px] h-[70px]">
                                                            <ProfileImageCOmponent urlProps={value?.employee_profile_image_url} />
                                                        </span>
                                                    </div>
                                                    :
                                                    <div className="me-5">
                                                        <span className="inline-block w-[50px] h-[75px] rounded">
                                                            <img
                                                                src={toAbsoluteUrl('/media/blank.svg')}
                                                                className="w-full h-full"
                                                                alt=""
                                                            />
                                                        </span>
                                                    </div>
                                            }

                                            <div className="flex flex-col justify-start" >
                                                <span className="text-dark font-bold mb-1 text-sm">
                                                    {(value?.reviewer_name) || '-'}
                                                </span>
                                                <span className="font-semibold mb-1 text-xs space-x-2" >
                                                    <span className='text-gray-400'>#</span>
                                                    <span className="text-dark">{(value?.reviewer_id || "-")}</span>
                                                </span>
                                                <span className="font-semibold text-xs space-x-2" >
                                                    <span className='text-gray-400'>
                                                        <Phone className="inline-block align-middle" size={12} />
                                                    </span>
                                                    <span className="text-dark">{(value?.reviewer_phone || "-")}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Approver</div>
                                        <div className="flex items-center">
                                            {
                                                value?.employee_profile_image_url ?
                                                    <div className="me-5">
                                                        <span className="inline-block w-[50px] h-[70px]">
                                                            <ProfileImageCOmponent urlProps={value?.employee_profile_image_url} />
                                                        </span>
                                                    </div>
                                                    :
                                                    <div className="me-5">
                                                        <span className="inline-block w-[50px] h-[75px] rounded">
                                                            <img
                                                                src={toAbsoluteUrl('/media/blank.svg')}
                                                                className="w-full h-full"
                                                                alt=""
                                                            />
                                                        </span>
                                                    </div>
                                            }

                                            <div className="flex flex-col justify-start" >
                                                <span className="text-dark font-bold mb-1 text-sm">
                                                    {(value?.approver_name) || '-'}
                                                </span>
                                                <span className="font-semibold mb-1 text-xs space-x-2" >
                                                    <span className='text-gray-400'>#</span>
                                                    <span className="text-dark">{(value?.approver_id || "-")}</span>
                                                </span>
                                                <span className="font-semibold text-xs space-x-2" >
                                                    <span className='text-gray-400'>
                                                        <Phone className="inline-block align-middle" size={12} />
                                                    </span>
                                                    <span className="text-dark">{(value?.approver_phone || "-")}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="font-bold text-xs text-gray-400">Images</div>
                                <div className="flex justify-around w-full">
                                    <div>
                                        <ImagesCarousel data={value && value || []} />
                                    </div>
                                    <div>
                                        <div className="font-bold text-xs text-gray-400">Qr Code</div>
                                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={value && value?.qr_code}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div className="font-bold text-xs text-gray-400">Map</div>
                                    <GISDashboardMain data={value} start_date={startDate} end_date={endDate} />
                                </div>

                                {/* {value && value?.status === "IN_REVIEW" ? <>
                                    <div className="w-full items-center">
                                        <div className="flex flex-col space-y-1.5">
                                            <Label htmlFor="name">Remarks</Label>
                                            <Textarea id="name" placeholder="Enter Remark" value={remark}
                                                onChange={(e) => setremark(e.target.value)} />
                                        </div>
                                    </div>


                                    <div className="flex justify-center">
                                        <div className="mr-2"> 
                                            <Button size={'lg'} className="bg-green-300  text-black hover:text-white hover:bg-green-400" onClick={() => {
                                                handleApproveStatus(value && value?.req_no)
                                            }}>
                                                Accept
                                            </Button>
                                        </div>
                                        <RejectModal data={value && value?.req_no} />
                                    </div>
                                </> : null
                                } */}


                            </CardContent>
                        </Card>
                        
                    </>)
                }) :
                    <>

                        {
                            isLoading && isLoading ? <UsersListLoading />
                                : <div className="bg-blue-100 text-blue-700 border border-blue-400 rounded-md px-4 py-3 flex justify-center items-center space-x-2" role="alert">
                                    <AlertCircle className='text-info ' /> <span>
                                        No Survey to Review, check Approve and Reject List.
                                    </span>
                                </div>
                        }

                    </>
                }
            </Card>
        </>
    )
}

export default TransformerDetailsCardExisitingDataNew