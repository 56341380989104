import { ColumnDef } from "@tanstack/react-table"
import { getFormatToDateTimeWithoutSecondsTogether } from "src/helpers"
import TransformerDetails from "./TransformerDetails"
import MarketerDetails from "./MarketerDetails"
import MeterDetails from "./MeterDetails"
import StatusDetails from "./StatusDetails"
import MiscDetails from "./MiscDetails"



export type ReportTable = {
  id: number,
  start_date: string,
  end_date: string,
  // is_success: number
  bym: string,
  dt: string
}


export const columns: ColumnDef<ReportTable>[] = [
  {
    accessorKey: "sr_no",
    header: "Sr no",
    cell: ({ ...props }) => <div className='text-center'>{props?.row?.getValue('sr_no')}</div>

  },
  {
    id: "transformer",
    header: "Transformer",
    cell: ({ ...props }) => <TransformerDetails rowData={props.row.original}></TransformerDetails >
  },
  {
    id: "marketer",
    header: "Line Man",
    cell: ({ ...props }) => <MarketerDetails rowData={props.row.original}></MarketerDetails >
  },
  {
    accessorKey: "req_no",
    header: "Request No",
    cell: ({ ...props }) => <div className=''>{props?.row?.getValue('req_no')}</div>
  },
  {
    accessorKey: "serial_no",
    header: "Serial No",
    cell: ({ ...props }) => <div className=''>{props?.row?.getValue('serial_no')}</div>
  },
  {
    id: "meter",
    header: "Meter No",
    cell: ({ ...props }) => <MeterDetails rowData={props.row.original}></MeterDetails>
  },

  {
    id: "status",
    header: "Status",
    cell: ({ ...props }) =>  <StatusDetails rowData={props?.row?.original}></StatusDetails>
    
  },

  {
    id: "misc",
    header: "Other Details",
    cell:({ ...props }) => <MiscDetails  rowData={props.row.original}></MiscDetails>
  },

  {
    accessorKey: "device_ts",
    header: "Created At",
    cell: ({ ...props }) => <div className='text-center'>{props?.row?.getValue('device_ts') 
    ? getFormatToDateTimeWithoutSecondsTogether(props?.row?.getValue('device_ts')) :"-"
    }</div>
  },
]
