import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { QueryState, stringifyObjectToRequestQuery, stringifyRequestQuery } from 'src/helpers';
import axios, { AxiosError } from 'axios';
import { getAllTransformers, getTransformersSummary } from 'src/modules/TransformerReport/Context/core/_requests';


interface IfilterReducerState extends QueryState {
    isLoading: boolean,
    error: boolean,
    message: string | undefined,
    tableData: any[],
    summaryData: any[],
    request_status: undefined | number,
    status: undefined | string,
    req_no: string | undefined,
    code: string | undefined,
    name: string | undefined,
    district_id: string,
    feeder_id: string,
    startDate: string,
    endDate: string,
    filterType: string,
    page: number
    items_per_page: 10 | 30 | 50 | 100
}


export const initaiState: IfilterReducerState = {
    isLoading: false,
    error: false,
    message: undefined,
    tableData: [],
    summaryData: [],
    request_status: undefined,
    status: undefined,
    req_no: undefined,
    code: undefined,
    name: undefined,
    district_id: "",
    feeder_id: "",
    startDate: "",
    endDate: "",
    filterType: "",
    page: 1,
    items_per_page: 30,
}


const transformToQueryString = (data: any) => {

    console.log({ data });


    const filterkeys: string[] = ["district_id", "feeder_id", "startDate", "endDate"];
    const f_keys: string[] = ["status"]
    const q_keys: string[] = ["req_no", "code", "name"];

    let filters: any = {};
    let _f: any = {};
    let _q: any = {};

    data && Object.entries(data).map(([key, value]) => {
        if (filterkeys.length > 0 && filterkeys.includes(key)) {
            filters[key] = value
        }
        else if (f_keys.length > 0 && f_keys.includes(key)) {
            _f[key] = value
        }
        else if (q_keys.length > 0 && q_keys.includes(key)) {
            _q[key] = value
        }
    })

    console.log({ filters, _f, _q });


    return stringifyRequestQuery({
        filter: filters,
        f: _f,
        q: _q,
        items_per_page: data.items_per_page,
        page: data.page
    })

}


export const fetchAllTransformersListAsync: any = createAsyncThunk(
    'AllTransformersListReducer/fetchAll',
    async (_, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().filterState
            console.log(getFilterState);

            // let query = {
            //     dist_id: getFilterState?.district_id,
            //     fd_id: getFilterState?.feeder_id,
            //     startDate: getFilterState?.startDate,
            //     endDate: getFilterState?.endDate,
            //     page: getFilterState?.page,
            //     items_per_page: getFilterState?.items_per_page
            // }

            const queryString = transformToQueryString(getFilterState);
            console.log({queryString})
            // if(getFilterState?.startDate && getFilterState?.endDate)


            let response: any = await getAllTransformers(queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = "";//response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const fetchTransformersSummaryAsync: any = createAsyncThunk(
    'AllTransformersSummaryReducer/fetchAll',
    async (_, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().filterState
            console.log(getFilterState);

            // let query = {
            //     dist_id: getFilterState?.district_id,
            //     fd_id: getFilterState?.feeder_id,
            //     startDate: getFilterState?.startDate,
            //     endDate: getFilterState?.endDate,
            //     page: getFilterState?.page,
            //     items_per_page: getFilterState?.items_per_page
            // }
            let newFilters = {...getFilterState}
            delete newFilters["status"]
            delete newFilters["req_no"]
            delete newFilters["name"]
            delete newFilters["code"]

            const queryString = transformToQueryString(newFilters);
            console.log({queryString})
            // if(getFilterState?.startDate && getFilterState?.endDate)


            let response: any = await getTransformersSummary(queryString);

            // console.log({ response })

            if (response.code === 200 && response.data) {
                return response?.data?.summary; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = "";//response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const filterReducer = createSlice({
    name: 'filterReducer',
    initialState: initaiState,
    reducers: {
        setDistrict: (state, action: PayloadAction<any>) => {
            state.district_id = action.payload;
        },
        setFeeder: (state, action: PayloadAction<any>) => {
            state.feeder_id = action.payload;
        },
        setStartDate: (state, action: PayloadAction<any>) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action: PayloadAction<any>) => {
            state.endDate = action.payload;
        },
        setType: (state, action: PayloadAction<any>) => {
            state.filterType = action.payload;
        },
        setPage: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
        setItemsPerPage: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload;
        },
        setPaggination: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload.pageSize;
            state.page = action.payload.pageIndex;
        },
        setFilters: (state, action: PayloadAction<{
            endDate: string,
            startDate: string,
            district_id: string,
            feeder_id: string
        }>) => {
            state.endDate = action.payload.endDate;
            state.startDate = action.payload.startDate;
            state.district_id = action.payload.district_id;
            state.feeder_id = action.payload.feeder_id;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload?.data?.message;
            state.request_status = action.payload?.status;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        reseteState: (state) => {
            state = initaiState
        },
        setQKeys: (state, action: PayloadAction<{
            
            req_no: string | undefined,
            code: string | undefined,
            name: string | undefined,
        }>) => {
            console.log(action);
            
            state.req_no = action.payload.req_no;
            state.code = action.payload.code;
            state.name = action.payload.name;
            console.log(state?.name);
            
        },
        setStatus: (state, action: PayloadAction<any>) => {
            state.status = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTransformersListAsync.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.tableData = payload.data;
                state.error = false;
                state.message = undefined;
                state.items_per_page = payload?.payload?.pagination?.items_per_page;
                state.page = payload?.payload?.pagination?.page;
                state.total_records = payload?.payload?.pagination?.total_records;
                state.total = payload?.payload?.pagination?.total;
            })
            .addCase(fetchAllTransformersListAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.tableData = []
                state.isLoading = true
            })
            .addCase(fetchAllTransformersListAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })
            .addCase(fetchTransformersSummaryAsync.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.summaryData = payload;
                state.error = false;
                state.message = undefined;
                state.items_per_page = payload?.payload?.pagination?.items_per_page;
                state.page = payload?.payload?.pagination?.page;
                state.total_records = payload?.payload?.pagination?.total_records;
                state.total = payload?.payload?.pagination?.total;
            })
            .addCase(fetchTransformersSummaryAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.summaryData = []
                state.isLoading = true
            })
            .addCase(fetchTransformersSummaryAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })
    }
});

export const { setDistrict, setFeeder, setStartDate, setEndDate, setType, setError, resetError, reseteState, setItemsPerPage, setPage, setPaggination, setFilters, setQKeys, setStatus } = filterReducer.actions;

export const getFilterReducerState = (state: RootState) => state.filterState;

export default filterReducer.reducer;