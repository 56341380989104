import { useFormik } from 'formik'
import React from 'react'
import { toAbsoluteUrl } from 'src/helpers'
import * as Yup from 'yup'
import { loginApi } from '../Context/core/_requests'
import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../Core'
import { useNavigate } from 'react-router-dom'

type Props = {}

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .required('Username is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})


const initialValues = {
    email: '',
    password: '',
}

const LoginPage = (props: Props) => {

    const { saveAuth, setCurrentUser } = useAuth()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values) => {

            handleSubmit(values)
        }
    })

    const handleSubmit = async (values: any) => {
        try {

            console.log("clicked login ");


            let data = {
                username: values?.email,
                password: values?.password,
                email: null
            }

            const apiResp = data && await loginApi(data);

            console.log({ apiResp });

            if (apiResp.code == 200) {
                // toast(apiResp.message, { type: toast.TYPE.SUCCESS });
                // setLoading(false);
                // onFormSubmit && onFormSubmit(false)
                // refetch()
                console.log(apiResp);


                saveAuth({
                    api_token: apiResp.data?.tokens?.access?.token,
                    user_id: apiResp.data.user.id,
                })

                setCurrentUser({
                    name: apiResp.data.user.username,
                    id: apiResp.data.user.id,
                    access_token: apiResp.data?.tokens?.access?.token,
                    phone: apiResp.data.user.phone
                })

                navigate("/")
            }
            else {
                toast(apiResp.message, { type: 'error' });
                // onFormSubmit && onFormSubmit(false)
            }

        } catch (e) {
            const error = e as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                toast(error.response?.data.message, { type: 'error' });
                console.log({ error: error.response });
            }
        }

    }


    return (
        <>
            <section className=" bg-no-repeat " style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/Login_background.svg')})`, backgroundSize: 'contain', backgroundPosition: "bottom"
            }}>
                <form
                    className='form w-full'
                    onSubmit={formik.handleSubmit}
                >
                    {/* <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0" >
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Sign in
                        </h1>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Username</label>
                            <input type="text" id="email"
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Enter Username" required
                                {...formik.getFieldProps('email')}
                                name='email'
                                autoComplete='off' />
                            {formik.touched.email && formik.errors.email && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.email}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Password</label>
                            <input type="password" id="password" placeholder="••••••••"
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                autoComplete='off'
                                {...formik.getFieldProps('password')} name="password" required />
                        </div>

                        <button className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Sign in</button>
                    </div>
                </div> */}

                    <div className="flex items-center justify-center h-screen ">
                        <div className="w-full max-w-md">
                            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0 dark:bg-gray-900">
                                <div className="space-y-4 w-full">
                                    <div className='font-bold text-center text-2xl text-blue-600'>
                                        Transformer Life Cycle
                                    </div>
                                    <div className="text-xl font-bold text-center leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Login
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Username</label>
                                        <input type="text" id="email"
                                            className=" bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Username"
                                            {...formik.getFieldProps('email')}
                                            name='email'
                                            autoComplete='off' />
                                        {/* {formik.touched.email && formik.errors.email && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span className='text-red'>{formik.errors.email}</span>
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Password</label>
                                        <input type="password" id="password" placeholder="••••••••"
                                            className="bg-gray-100  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            autoComplete='off'
                                            {...formik.getFieldProps('password')} name="password" required />
                                    </div>

                                    <button className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Sign in</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section >
        </>
    )
}

export default LoginPage