import { optionsType } from "src/types";


export interface ContextProps {
    selectedValues: optionsType | undefined;
    toggleValue: (e: any) => void,
}

export const initialContext = {
    selectedValues: undefined,
    toggleValue: () => { }
}
