const API_URL = process.env.REACT_APP_BASE_URL + "/api/v1"
const REPORT_SERVICE_API_URL = process.env.REACT_APP_API_URL_REPORT_SERVICE + "/api/v1";
const BE_SERVICE_API_URL = process.env.REACT_APP_API_URL_BE_SERVICE + "/api/v1";

const AUTH_SERVICE = process.env.REACT_APP_API_URL_AUTH_SERVICE_NEW

// Auth
export const LOGIN_URL = AUTH_SERVICE + "/login"
export const PROFILE_URL = AUTH_SERVICE + "/profile"

// Filters
export const URL_DISTRICT_FILTER = REPORT_SERVICE_API_URL + "/dd/districts"
export const URL_TCN_SUBSTATION_FILTER = BE_SERVICE_API_URL + "/districts/dd/tcn-substation-list"
export const URL_GET_TCN_TRANSFORMER_BY_ID = BE_SERVICE_API_URL + "/transformers/dd/tcn-power-transformer"
export const URL_FEEDERS_FILTER = REPORT_SERVICE_API_URL + "/dd/feeders"

// Transformer APIs
export const URL_GET_ALL_TRANSFORMERS = API_URL + "/transformer/get/data"
export const URL_GET_TRANSFORMER_SUMMARY = API_URL + "/transformer/get/record-summary"
export const URL_TRANSFORMER_DETAILS_BY_ID = (ID: string) => API_URL + "/transformer/get/data/code" + "/" + ID;
export const URL_TRANSFORMER_STATUS_UPDATE_BY_ID = (ID: string) => API_URL + "/transformer/change/status" + "/" + ID;
export const URL_APPROVED_TRANSFORMER_DETAILS_BY_ID = (ID: string) => API_URL + "/transformer/get/approve/data" + "/" + ID;
export const URL_REPLACE_TRANSFORMER_DETAILS = API_URL + "/transformer/add/approval/data";
export const URL_APPROVED_TRANSFORMER_LIST = API_URL + "/transformer/get/approve/data";
export const URL_APPROVED_TRANSFORMER_EXPORT = API_URL + "/transformer/get/excel";