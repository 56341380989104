import { BadgeAlert, BadgeCheck, RefreshCcw, XCircle } from 'lucide-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OptionsComponentWithIcons from 'src/components/commons/OptionsComponentWithIcons'
import SearchInputByOptions from 'src/components/commons/SearchInputByOptions'
import SelectPicker from 'src/components/ui/SelectPicker'
import { Button } from 'src/components/ui/button'
import { OptionType } from 'src/helpers'
import { fetchAllApprovedTransformersListAsync, getApprovedFilterReducerState, setQKeys } from 'src/redux/Reducers/approvedFilterReducer'
import { RootState } from 'src/redux/store'
import ExcelExport from './ExcelExport'


type Props = {}


const SubFilters = (props: Props) => {

    const dispatch = useDispatch()
    const { status } = useSelector((state: RootState) => getApprovedFilterReducerState(state))

    const handleRefresh = () => {
        dispatch(fetchAllApprovedTransformersListAsync())
    }

    return (
        <>
            <div className="flex justify-end items-center">

                <SearchInputByOptions options={{
                    "req_no": 'Request No',
                    "name": "Transformer Name",
                    "code": "Transformer Code",
                }} onChange={(e: any) => {
                    dispatch(setQKeys({
                        req_no: e?.key == "req_no" ? e?.value : undefined,
                        code: e?.key == "code" ? e?.value : undefined,
                        name: e?.key == "name" ? e?.value : undefined
                    }))
                    dispatch(fetchAllApprovedTransformersListAsync())
                }} />

                <ExcelExport />

                <Button variant="ghost" onClick={handleRefresh} ><RefreshCcw size={16} /></Button>

            </div>
        </>
    )
}

export default SubFilters