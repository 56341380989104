
import { BadgeAlert, BadgeCheck, RefreshCcw, XCircle } from 'lucide-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OptionsComponentWithIcons from 'src/components/commons/OptionsComponentWithIcons'
import SearchInputByOptions from 'src/components/commons/SearchInputByOptions'
import SelectPicker from 'src/components/ui/SelectPicker'
import { Button } from 'src/components/ui/button'
import { OptionType } from 'src/helpers'
import { fetchAllTransformersListAsync, fetchTransformersSummaryAsync, getFilterReducerState, setQKeys, setStatus } from 'src/redux/Reducers/filterReducer'
import { RootState } from 'src/redux/store'


type Props = {}

export const TRANSFORMER_STATUS_OPTIONS_ICONS: OptionType[] = [
    {
        label: 'ALL',
        value: "all",
        key: "TRANSFORMER_REPORT_STATUS_OPTIONS-ALL",
        // icon: <RiCheckboxCircleFill size={15} className='text-success' /> as any,
        // extraData: <div>(10)</div>
    },
    {
        label: 'Approved',
        value: "APPROVED",
        key: "TRANSFORMER_REPORT_STATUS_OPTIONS-APPROVED",
        icon: <BadgeCheck size={20} className='text-green-600' /> as any,
        // extraData: <div>(10)</div>
    },
    {
        label: 'Rejected',
        value: "REJECTED",
        key: "TRANSFORMER_REPORT_STATUS_OPTIONS-REJECTED",
        icon: <XCircle size={20} className='text-red-400' /> as any
    },
    {
        label: 'In Review',
        value: "IN_REVIEW",
        key: "TRANSFORMER_REPORT_STATUS_OPTIONS-IN_REVIEW",
        icon: <BadgeAlert size={20} className='text-amber-600' /> as any
    },
]

const SubFilters = (props: Props) => {

    const dispatch = useDispatch()
    const { status } = useSelector((state: RootState) => getFilterReducerState(state))

    const handleRefresh = () => {
        dispatch(fetchAllTransformersListAsync())
        dispatch(fetchTransformersSummaryAsync())
    }

    return (
        <>
            <div className="flex justify-end items-center">
                {/* <OptionsComponentWithIcons value={status || "all"} options={TRANSFORMER_STATUS_OPTIONS_ICONS} name="TRANSFORMER-STATUS-CODES"
                    onchange={(e: OptionType) => dispatch(setStatus(e.value))} /> */}
                <SelectPicker options={TRANSFORMER_STATUS_OPTIONS_ICONS} onchange={(e: any) => {

                    dispatch(setStatus(e != 'all' ? e : undefined));
                    dispatch(fetchAllTransformersListAsync())
                }} value={status} searchable={false} placeholder='Select Status' />
                <SearchInputByOptions options={{
                    // "meter_no": "Meter No",
                    "req_no": 'Request No',
                    "name": "Transformer Name",
                    "code": "Transformer Code",
                }} onChange={(e: any) => {
                    console.log(e?.key, e?.value);

                    dispatch(setQKeys({
                        req_no: e?.key == "req_no" ? e?.value : undefined,
                        code: e?.key == "code" ? e?.value : undefined,
                        name: e?.key == "name" ? e?.value : undefined
                    }))
                    dispatch(fetchAllTransformersListAsync())
                }} />

                <Button variant="ghost" onClick={handleRefresh} ><RefreshCcw size={16} /></Button>

            </div>
        </>
    )
}

export default SubFilters