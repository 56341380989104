import { useEffect, useMemo, useState } from 'react';
import { QueryRequestProvider, QueryResponseProvider, ApprovedTransformerTable } from './Context';
import { Card } from 'src/components/ui/card';
import TransformerFilters from './Components/TransformerFilters';
import SubFilters from './Components/SubFilters';



type Props = {}

const Main = (props: Props) => {
    return <>

        <div className='p-2 space-y-2'>
            <div className="flex justify-between items-center ">
                <div className='justify-self-start text-center font-bold'>Approved Report</div>
                <div className='flex justify-end space-x-2 items-center'>
                    <TransformerFilters />
                </div>
            </div>
            <Card className='space-y-2 p-2'>
                <SubFilters />
                <ApprovedTransformerTable />
            </Card>
        </div>
    </>
}

const ApprovedTransformerMain: React.FC<Props> = ({ }) => {
    return <>
        <Main />
    </>

}

export default ApprovedTransformerMain