import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthInit } from './modules/Auth/Core';




function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
      <AuthInit >
        <QueryClientProvider client={queryClient}>
          <Outlet />
          <ToastContainer hideProgressBar={true} pauseOnHover />
        </QueryClientProvider>
      </AuthInit>
    </>
  );
}

export default App;
