import React, { useEffect, useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'src/components/ui/dialog'
import { Input } from 'src/components/ui/input'
import { Label } from 'src/components/ui/label'
import { Separator } from 'src/components/ui/separator'
import { useAuth } from 'src/modules/Auth/Core'
import { getUpdateStatusReducerState, replaceTransformersStatusByReqNoAsync, resetError, resetToggleModale, setRemarks, setReqNo, setStatus, updateTransformersStatusByReqNoAsync } from 'src/redux/Reducers/updateStatusReducer'
import { RootState } from 'src/redux/store'

type Props = {
    message?: any,
    show?: boolean, data?: any
}

const ExistingDataModal = ({ message, data }: Props) => {

    const [remark, setremark] = useState("")
    const dispatch = useDispatch()
    const { showModal, showModalMessage, error, status, message: errorMessage } = useSelector((state: RootState) => getUpdateStatusReducerState(state))
    // console.log(showModal);

    const { currentUser } = useAuth();

    const [isOpen, setisOpen] = useState("hide")


    const newTabForExistingDetails = () => {
        window.open(`/dashboard/new-existing-details?code=${data?.code}`, '_blank')
    }


    const onSkip = () => {
        dispatch(setStatus("REJECTED"))
        dispatch(setReqNo(data && data?.req_no))
        // dispatch(setRemarks(remark))
        dispatch(updateTransformersStatusByReqNoAsync({currentUser}))
        dispatch(resetToggleModale())
        // setisOpen("hide")
    }

    const onReplace = () => {
        dispatch(setStatus("APPROVED"))
        dispatch(setReqNo(data && data?.req_no))
        // dispatch(setRemarks(remark))
        dispatch(replaceTransformersStatusByReqNoAsync({currentUser}))
        dispatch(resetToggleModale())
        // setisOpen("hide")
    }

    


    return (
        <>
            <Dialog open={showModal} onOpenChange={(e) => dispatch(resetToggleModale())} >
                <DialogContent className="lg:max-w-[1025px]">
                    <DialogHeader>
                        <DialogTitle>Existing Data Found !!!</DialogTitle>
                    </DialogHeader>
                    <Separator />


                    <div className="font-bold text-center cursor-pointer hover:text-blue-500" onClick={newTabForExistingDetails}>
                        {showModalMessage && showModalMessage || "-"}
                    </div>

                    {/* <form>
                        <div className="w-full items-center">
                            <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="name">Remarks</Label>
                                <Input id="name" placeholder="Enter Remark" value={remark}
                                    onChange={(e) => setremark(e.target.value)} />
                            </div>
                        </div>
                    </form> */}


                    <DialogFooter >
                        <div className="flex justify-between">
                            <div className="mr-2"> {/* Add margin-right to separate the buttons */}
                                <Button className="bg-secondary text-black hover:text-white hover:bg-blue-400" onClick={onSkip}>
                                    Skip
                                </Button>
                            </div>
                            <div>
                                <Button className="bg-secondary text-black hover:text-white hover:bg-blue-400" onClick={onReplace}>
                                    Replace
                                </Button>
                            </div>
                        </div>

                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ExistingDataModal