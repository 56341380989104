
import { BadgeCheck, BarChart3, Calculator, Dot, Home, LandPlot, Layers3, ListFilter, Workflow } from 'lucide-react'


export interface IMenuItem {
    name: string,
    iconName: any,
    path: string,
    options?: Array<{
        name: string,
        iconName: any,
        path: string,
    }>
}

export interface IMenu {
    heading: string,
    options?: Array<{
        name: string,
        iconName: any,
        path: string,
    }>
}
export const MenuOptions: IMenuItem[] = [
    // {
    //     name: "Dashboard",
    //     iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
    //     path: "/dashboard",

    // },
    {
        name: "Jobs Status",
        iconName: (className: string) => <Dot size={18} className={className} />,
        path: "/pages"
    },
    {
        name: "Summary By Month/Year",
        iconName: (className: string) => <Dot size={18} className={className} />,
        path: "/pages/collection-summary"
    },
    {
        name: "Users Summary By Activity",
        iconName: (className: string) => <Dot size={18} className={className} />,
        path: "/pages/summary-act"
    },
    {
        name: "Users Monthly Report",
        iconName: (className: string) => <Dot size={18} className={className} />,
        path: "/pages/user-act"
    },
    {
        name: "Points Breakup",
        iconName: (className: string) => <Dot size={18} className={className} />,
        path: "/pages/bucketwise-point"
    },

    {
        name: "Sandbox",
        iconName: (className: string) => <Dot size={18} className={className} />,
        path: "/pages/editable-comp"
    },
    {
        name: "Target Overflow",
        iconName: (className: string) => <Dot size={18} className={className} />,
        path: "/pages/target-overflow"
    },
]


export const Menu: IMenu[] = [
    {
        heading: 'HOME',
        options: [
            {
                name: "Dashboard",
                iconName: (className: string) => <Home size={18} className={className} />,
                path: "/dashboard"
            },
            {
                name: "Approved",
                iconName: (className: string) => <BadgeCheck size={18} className={className} />,
                path: "/approved-details"
            },

        ]
    },
    // {
    //     heading: 'PIM & ENERGY',
    //     options: [
    //         {
    //             name: "Dashboard",
    //             iconName: (className: string) => <PlugZap size={18} className={className} />,
    //             path: "/more-charts/cards/dash/district-wise-pim"
    //         },

    //     ]
    // },
    // {
    //     heading: 'FEEDER',
    //     options: [
    //         {
    //             name: "All Feeders",
    //             iconName: (className: string) => <SquareStack size={18} className={className} />,
    //             path: "/dashboard/feeder/analysis"
    //         },
    //         {
    //             name: "GIS",
    //             iconName: (className: string) => <Map size={18} className={className} />,
    //             path: "/dashboard/gis"
    //         },
    //         {
    //             name: "Network Map",
    //             iconName: (className: string) => <Network size={18} className={className} />,
    //             path: "/dashboard/net-map"
    //         },
    //         {
    //             name: "Uptime & Downtime Analysis",
    //             iconName: (className: string) => <ArrowDownUp size={18} className={className} />,
    //             path: "/dashboard/trip-status"
    //         },
    //     ]
    // },
    // {
    //     heading: 'ANALYTICAL REPORTS',
    //     options: [
    //         {
    //             name: "Dashboards",
    //             iconName: (className: string) => <BarChart3 size={18} className={className} />,
    //             path: "/more-charts/cards/dash"
    //         },

    //     ]
    // },
    // {
    //     heading: 'CRON JOB',
    //     options: [
    //         {
    //             name: "Meter Reading",
    //             iconName: (className: string) => <ParkingMeter size={18} className={className} />,
    //             path: "/cron/meter-reading"
    //         },
    //         {
    //             name: "Load Interval",
    //             iconName: (className: string) => <Gauge size={18} className={className} />,
    //             path: "/cron/load-interval"
    //         },

    //     ]
    // },


]