import axios, { AxiosError, AxiosResponse } from 'axios'
import { URL_GET_ALL_TRANSFORMERS, URL_GET_TRANSFORMER_SUMMARY } from 'src/apis'


export const getAllTransformers = (query: string) => {
  return axios
    .get(`${URL_GET_ALL_TRANSFORMERS}?${query}`)
    .then((d: AxiosResponse<any>) => d?.data)
}

export const getTransformersSummary = (query: string) => {
  return axios
    .get(`${URL_GET_TRANSFORMER_SUMMARY}?${query}`)
    .then((d: AxiosResponse<any>) => d?.data)
}







